import Client from "./Client";

class Booking extends Client {
  async getList(payload) {
    let params = {
      page: payload.page,
      status: payload.status,
      preset_id: payload.preset_id,
    };
    if (payload.start_at) {
      params.start_at = payload.start_at;
      params.end_at = payload.end_at;
    }
    const {
      data,
      meta: { last_page: lastPage },
    } = await this.get(`/`, params);

    return { data, lastPage };
  }

  async getDetail({ id }) {
    const { data } = await this.get(`/${id}`);

    return data;
  }

  async getProvider() {
    return this.get(`/provider`);
  }

  async saveBooking(params) {
    return await this.post('', params)
  }
}

export default new Booking({
  baseURL: "/:orgCode/liff/bookings",
});
