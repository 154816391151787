import https from "./https";
import store from "@/store";

const bookingPresetApi = {
  async get(id) {
    const organization = store.state.liffGeneral.orgCode;

    let { data } = await https.get(
      `${organization}/liff/booking-presets/${id}`
    );

    return data.data;
  },

  // for reservation type use
  async getStaffList(id) {
    const organization = store.state.liffGeneral.orgCode
    let { data } = await https.get(`${organization}/liff/booking-presets/${id}/staffs`)

    return data.data
  },

  async getBranchList(id) {
    const organization = store.state.liffGeneral.orgCode
    let { data } = await https.get(`${organization}/liff/booking-presets/${id}/branches`)

    return data.data
  },

  async getCollectionItemList(id) {
    const organization = store.state.liffGeneral.orgCode
    let { data } = await https.get(`${organization}/liff/booking-presets/${id}/collectionItems`)

    return data.data
  }
};

export default bookingPresetApi;
